import { FC, useState } from "react";

import style from "./LabelItem.module.scss";

import { LabelResponseType, UnaccLabelResponseType } from "../../utils/types";
import { svgPen } from "../../utils/svgBoxes";
import EditLabelStatus from "./EditLabel";
import { fetchDeleteUnacLabel } from "../../utils/requests";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setUnacLabels } from "../../redux/slices/labelsSlice";
import { setUnauthorized } from "../../redux/slices/authSlice";
import { formatDate } from "../../utils/helpers";

interface ILabelItem {
  label: LabelResponseType | UnaccLabelResponseType;
}

const LabelItem: FC<ILabelItem> = ({ label }) => {
  const authData = useAppSelector((state) => state.auth.authData);
  const unacLabels = useAppSelector((state) => state.labels.unaccLabels);

  const [editStatus, setEditStatus] = useState(false);
  const [idDeleting, setIdDeleting] = useState(false);

  const dispatch = useAppDispatch();

  const deleteLabelHandler = async () => {
    setIdDeleting(true);
    try {
      const response = await fetchDeleteUnacLabel(label.id, authData.token);
      if (response.ok) {
        const newUnacLabels = unacLabels.filter((el) => el.id !== label.id);
        dispatch(setUnacLabels(newUnacLabels));
      } else if (response?.status === 401) {
        localStorage.removeItem("authData");
        dispatch(setUnauthorized(true));
      }
    } catch (error) {
      console.error(error);
    }
    setIdDeleting(false);
  };

  const editStatusHandler = () => {
    setEditStatus(true);
  };

  const isLabel = "status" in label;

  const status = () => {
    if (isLabel) {
      const styleStatus =
        label.status === "PENDING" ? style.statusPending : style.statusPrinted;
      return <span className={styleStatus}>{label.status}</span>;
    }
    return;
  };

  return (
    <div className={isLabel ? style.item : `${style.item} ${style.itemUnacc}`}>
      <span>{label.id}</span>
      <span>{label.barcode}</span>
      {status()}
      <span>{label.rus_name}</span>
      <span>
        {isLabel
          ? label.updated_at
            ? formatDate(label.updated_at)
            : "-"
          : label.scanned_at
          ? formatDate(label.scanned_at)
          : "-"}
      </span>
      <span>{(isLabel ? label.updated_by : label.scanned_by) || "-"}</span>
      {isLabel ? (
        label.status === "PRINTED" && (
          <div onClick={editStatusHandler} className={style.editStatus}>
            {svgPen("white")}
          </div>
        )
      ) : idDeleting ? (
        <div className={style.loaderBox}>
          <div className={style.loader}></div>
        </div>
      ) : (
        <div onClick={deleteLabelHandler} className={style.close}>
          <span className={style.one}></span>
          <span className={style.two}></span>
        </div>
      )}
      {editStatus && isLabel && (
        <EditLabelStatus label={label} setEditStatus={setEditStatus} />
      )}
    </div>
  );
};

export default LabelItem;
