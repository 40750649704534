import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

import style from "./OrderItem.module.scss";

import EditOrder from "./EditOrder";
import NewLabelsToOrder from "../Labels/NewLabelsToOrder";
import { formatDate } from "../../utils/helpers";
import { OrderResponseType } from "../../utils/types";
import { fetchDownloadXlsx } from "../../utils/requests";
import { setUnauthorized } from "../../redux/slices/authSlice";
import {
  setCurrentOrderID,
  setCurrentOrderName,
} from "../../redux/slices/labelsSlice";
import {
  svgArrow,
  svgCross,
  svgDownloadXlsx,
  svgPen,
} from "../../utils/svgBoxes";

interface IOrderItem {
  order: OrderResponseType;
  setCurrentOrder: (val: OrderResponseType) => void;
  setDetailsOrderClecked: (val: boolean) => void;
  setIsNewLabelsAddedMsg: (val: boolean) => void;
  setIsAddLabelsError: (val: boolean) => void;
}

const OrderItem: FC<IOrderItem> = ({
  order,
  setDetailsOrderClecked,
  setCurrentOrder,
  setIsNewLabelsAddedMsg,
  setIsAddLabelsError,
}) => {
  const authData = useAppSelector((state) => state.auth.authData);

  const [editOrder, setEditOrder] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [isAddLabelWindow, setAddLabelWindow] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const accessLevel = authData.access_level;

  const onClickEditOrderHandler = () => {
    if (accessLevel > 0 && order.status !== "FINISHED") {
      setEditOrder(true);
    }
  };

  const onClickAddLabel = () => {
    if (accessLevel > 0 && order.status !== "FINISHED") {
      setAddLabelWindow(true);
    }
  };

  const onClickToOrder = () => {
    if (accessLevel > 0) {
      dispatch(setCurrentOrderID(order.id));
      dispatch(setCurrentOrderName(order.order_name));
      navigate("/admin");
    } else {
      setCurrentOrder(order);
      setDetailsOrderClecked(true);
    }
  };

  const onClickToDownload = async () => {
    if (accessLevel < 1) return;
    try {
      const response = await fetchDownloadXlsx(order.id, authData.token);
      if (response?.ok) {
        const blob = await response.blob();
        const fileUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = fileUrl;
        link.setAttribute(
          "download",
          `Order_№${order.id}_${order.order_name.split(" ").join("")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else if (response?.status === 401) {
        localStorage.removeItem("authData");
        dispatch(setUnauthorized(true));
      } else if (response?.status === 404) {
        setNotFound(true);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const isFinished = order.status === "FINISHED" ? true : false;

  let statusStyle = "";
  if (order.status === "PROCESSING") {
    statusStyle = style.processingStatus;
  } else if (order.status === "FINISHED") {
    statusStyle = style.finishedStatus;
  }

  const penCrossButton = (
    imageFn: (color: string) => JSX.Element,
    handler: () => void
  ) => {
    return (
      <button
        className={accessLevel < 1 || isFinished ? style.nonAccessButtons : ""}
        onClick={handler}
      >
        {accessLevel < 1 || isFinished ? imageFn("#0f3c81") : imageFn("white")}
      </button>
    );
  };

  return (
    <>
      <div className={`${style.dataTitle} ${style.order}`}>
        <span>{order.id}</span>
        <span>{order.client_id}</span>
        <span>{order.worker_id}</span>
        <span>{order.order_name}</span>
        <span>{formatDate(order.created_at)}</span>
        <span className={statusStyle}>{order.status}</span>
        <span>{order.labels_count}</span>
        <div className={style.btnPanel}>
          {penCrossButton(svgPen, onClickEditOrderHandler)}
          {penCrossButton(svgCross, onClickAddLabel)}
          <button
            onClick={isFinished ? onClickToDownload : onClickToOrder}
            className={style.lastOrderButtons}
          >
            {isFinished ? svgDownloadXlsx("white") : svgArrow("white")}
          </button>
          {notFound && (
            <div className={style.background}>
              <div className={style.errorMessage}>
                <p>Order is not found!</p>
                <div onClick={() => setNotFound(false)} className={style.close}>
                  <span className={style.one}></span>
                  <span className={style.two}></span>
                </div>
              </div>
            </div>
          )}
        </div>
        {editOrder && <EditOrder setEditOrder={setEditOrder} order={order} />}
        {isAddLabelWindow && (
          <NewLabelsToOrder
            order={order}
            setAddLabelWindow={setAddLabelWindow}
            setIsNewLabelsAddedMsg={setIsNewLabelsAddedMsg}
            setIsAddLabelsError={setIsAddLabelsError}
          />
        )}
      </div>
    </>
  );
};

export default OrderItem;
