import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../redux/hooks";

import style from "./Login.module.scss";

import { LoginType } from "../utils/types";
import { fetchAuth } from "../utils/requests";
import {
  setAuthData,
  setLogouting,
  setUnauthorized,
} from "../redux/slices/authSlice";
import logo from "../assets/img/LabelMatrixMainLogo.png";

const Login: FC = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setLoading] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    getValues,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginType>();

  const onSubmit = async () => {
    setErrorMessage("");
    setLoading(true);
    try {
      const response = await fetchAuth(getValues());
      if (response.ok) {
        const data = await response.json();
        const localAuthData = {
          ...data,
          created: Date.now(),
          login: getValues("login"),
        };
        dispatch(setAuthData(localAuthData));
        dispatch(setUnauthorized(false));
        localStorage.setItem("authData", JSON.stringify(localAuthData));
        navigate("../orders");
        window.location.reload();
      } else if (response.status === 400) {
        setErrorMessage("BAD REQUEST");
      } else if (response.status === 401) {
        setErrorMessage("WRONG LOGIN OR PASSWORD");
      } else {
        setErrorMessage("SERVER ERROR");
      }
    } catch (error) {
      setErrorMessage("NETWORK ERROR");
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    dispatch(setLogouting(false));
  }, [dispatch]);

  useEffect(() => {
    document.title = "Label Matrix | Login";
  }, []);

  const inputStyle = `${style.input} ${style.inputWrong}`;

  return (
    <div className={style.main}>
      <div className={style.logoBox}>
        <img src={logo} alt="" />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <span className={style.title}>AUTHENTICATION</span>
        <label>
          LOGIN
          <input
            type="text"
            className={errors.login ? inputStyle : style.input}
            {...register("login", { required: true, maxLength: 20 })}
          />
        </label>
        <label>
          PASSWORD
          <input
            className={errors.password ? inputStyle : style.input}
            type="password"
            {...register("password", { required: true, maxLength: 20 })}
          />
        </label>
        {isLoading ? (
          <div className={style.disableLoaderBtn}>
            <div className={style.loader}></div>
          </div>
        ) : (
          <input className={style.button} type="submit" value="LOG IN" />
        )}
        {errorMessage && <div className={style.wrongAuth}>{errorMessage}</div>}
      </form>
    </div>
  );
};

export default Login;
