import { FC } from "react";
import { WSStatus } from "jsprintmanager";
import { useAppSelector } from "../../redux/hooks";

import style from "./JspmStatusBox.module.scss";

const JspmStatusBox: FC = () => {
  const { jspmStatus } = useAppSelector((state) => state.jspm);

  const statusStyle =
    jspmStatus === WSStatus.Open ? style.statusWorking : style.statusNotWorking;

  return (
    <span className={style.jspm}>
      <span>JSPM status:</span>
      <span className={statusStyle}>
        {jspmStatus === WSStatus.Open ? "Working" : "Not Working"}
      </span>
    </span>
  );
};

export default JspmStatusBox;
