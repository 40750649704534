import { FC, useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import style from "./App.module.scss";

import Login from "./pages/Login";
import Orders from "./pages/Orders";
import Clients from "./pages/Clients";
import SideBar from "./components/SideBar";
import AdminPanel from "./pages/AdminPanel";
import DeviceTesting from "./pages/DeviceTesting";
import { AuthType } from "./utils/types";
import { setAuthData } from "./redux/slices/authSlice";
import { getClientsData, setClientsError } from "./redux/slices/clientsSlice";
import { getOrdersData, setOrdersError } from "./redux/slices/ordersSlice";
import { getJspmStatus } from "./redux/slices/jspmSlice";

const App: FC = () => {
  const { authData, unauthorized, isLogouting } = useAppSelector(
    (state) => state.auth
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation().pathname;

  const isResponse = useRef(false);

  useEffect(() => {
    dispatch(getJspmStatus());

    if (authData.token) {
      if (!isResponse.current) {
        dispatch(setClientsError(false));
        dispatch(setOrdersError(false));
        dispatch(getOrdersData(authData));
        dispatch(getClientsData(authData));
      }
      isResponse.current = true;
    }
  }, [authData, dispatch]);

  useEffect(() => {
    if (location === "/") navigate("/orders");
    if (!unauthorized && location === "/login") navigate("/orders");
  }, [dispatch, navigate, location, unauthorized]);

  useEffect(() => {
    const storageData = localStorage.getItem("authData");
    if (storageData) {
      const localAuthData: AuthType = JSON.parse(storageData);
      dispatch(setAuthData(localAuthData));
    } else {
      navigate("/login");
    }
  }, [dispatch, navigate]);

  useEffect(() => {
    if (unauthorized) navigate("/login");
  }, [navigate, unauthorized]);

  function MainContent(isShow: boolean, Component: JSX.Element) {
    return (
      <div className={style.main}>
        <SideBar username={authData ? authData.worker_name : null} />
        <div className={style.mainContent}>
          {isShow ? (
            Component
          ) : (
            <div className={style.accessLevel}>ACCESS LEVEL IS NOT ENOUGH</div>
          )}
        </div>
      </div>
    );
  }

  function routedContent(path: string, Component: JSX.Element) {
    let isShow = true;
    if (authData && authData.access_level < 1 && path === "/clients") {
      isShow = false;
    }
    return <Route path={path} element={MainContent(isShow, Component)} />;
  }

  if (isLogouting) return <></>;

  return (
    <div className={style.app}>
      <Routes>
        <Route path="/login" element={<Login />} />
        {routedContent("/orders", <Orders mainTitle="ORDERS" />)}
        {routedContent("/print-test", <DeviceTesting />)}
        {routedContent("/clients", <Clients mainTitle="CLIENTS" />)}
        {routedContent("/admin", <AdminPanel mainTitle="ADMIN PANEL" />)}
      </Routes>
    </div>
  );
};

export default App;
