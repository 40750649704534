import { FC } from "react";

import style from "./MainTitle.module.scss";

const MainTitle: FC<{ title: string }> = ({ title }) => {
  return (
    <header className={style.header}>
      <div className={style.title}>{title}</div>
    </header>
  );
};

export default MainTitle;
