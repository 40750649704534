import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

import style from "../Clients/NewClient.module.scss";

import FileInput from "./../FileInput";
import { LabelsSendType, NewOrderType } from "../../utils/types";
import { fetchAddOrder } from "../../utils/requests";
import {
  svgNewOrderWindow,
  svgOrderName,
  svgPerson,
} from "../../utils/svgBoxes";
import { getOrdersData } from "../../redux/slices/ordersSlice";
import { setUnauthorized } from "../../redux/slices/authSlice";

interface INewOrder {
  setAddOrderClicked: (value: boolean) => void;
  setIsNewOrderAddedMsg: (value: boolean) => void;
  setIsAddLabelsError: (value: boolean) => void;
}

const NewOrder: FC<INewOrder> = ({
  setAddOrderClicked,
  setIsNewOrderAddedMsg,
  setIsAddLabelsError,
}) => {
  const authData = useAppSelector((state) => state.auth.authData);
  const clients = useAppSelector((state) => state.clients.clients);

  const [csvData, setCSVData] = useState<LabelsSendType[]>([]);
  const [file, setFile] = useState<File>();

  const {
    getValues,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<NewOrderType>();

  setValue("order_name", "INV ");

  const dispatch = useAppDispatch();

  const onSubmit = async () => {
    try {
      if (authData && file) {
        const response = await fetchAddOrder(
          {
            client_id: +getValues().client_id,
            order_name: getValues().order_name,
            labels: csvData,
          },
          +getValues().client_id,
          authData.token
        );
        if (response?.ok) {
          setAddOrderClicked(false);
          dispatch(getOrdersData(authData));
          setIsNewOrderAddedMsg(true);
        } else if (response?.status === 401) {
          localStorage.removeItem("authData");
          dispatch(setUnauthorized(true));
        } else {
          setIsAddLabelsError(true);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const inputStyle = `${style.input} ${style.inputWrong}`;

  return (
    <>
      <div className={style.main}>
        <div className={style.window}>
          <form action="" onSubmit={handleSubmit(onSubmit)}>
            <div className={style.titleBox}>
              {svgNewOrderWindow("white")}
              <label className={style.title}>NEW ORDER</label>
            </div>
            <label>
              <p className={style.inputTitle}>ORDER NAME</p>
              <div className={style.svgBox}>{svgOrderName("#0F3C81")}</div>
              <input
                className={errors.order_name ? inputStyle : style.input}
                style={{ paddingLeft: "55px" }}
                type="text"
                {...register("order_name", { required: true })}
              />
            </label>
            <label>
              <p className={style.inputTitle}>CLIENT</p>
              <div className={style.svgBox}>{svgPerson("#0F3C81")}</div>
              <select {...register("client_id", { required: true })}>
                {clients.map((client) => (
                  <option key={client.id} value={client.id}>
                    {`ID ${client.id} | ${client.name}`}
                  </option>
                ))}
              </select>
            </label>
            <div>
              <label style={{ marginBottom: "10px" }}>CSV FILE</label>
              <FileInput
                file={file}
                setFile={setFile}
                setCSVData={setCSVData}
              />
            </div>
            <input className={style.button} type="submit" value="ADD" />
            <div
              onClick={() => setAddOrderClicked(false)}
              className={style.close}
            >
              <span className={style.one}></span>
              <span className={style.two}></span>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default NewOrder;
