import { FC } from "react";
import { useAppSelector } from "../../redux/hooks";

import style from "../ManagementPanel.module.scss";

import Pagination from "./../Pagination";
import { svgNewClient } from "../../utils/svgBoxes";

interface IClientManagementPanel {
  currentPage: number;
  setCurrentPage: (value: number) => void;
  onButtonHandler: (value: boolean) => void;
}

const ClientManagementPanel: FC<IClientManagementPanel> = ({
  currentPage,
  setCurrentPage,
  onButtonHandler,
}) => {
  const clients = useAppSelector((state) => state.clients.clients);

  return (
    <div className={style.main}>
      <div
        className={`${style.redButton} ${style.Btn}`}
        onClick={() => {
          onButtonHandler(true);
        }}
      >
        {svgNewClient("white")}
        <span>NEW CLIENT</span>
      </div>
      <Pagination
        dataSize={clients.length}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
};

export default ClientManagementPanel;
