import { FC, useEffect } from "react";

import style from "./InfoMessage.module.scss";

interface IInfoMessage {
  isError: boolean;
  text: string;
  opened: boolean;
  closeMessage: (val: boolean) => void;
}

const InfoMessage: FC<IInfoMessage> = ({
  text,
  opened,
  isError,
  closeMessage,
}) => {
  useEffect(() => {
    setTimeout(() => {
      closeMessage(false);
    }, 2000);
  }, [closeMessage]);

  return (
    <div className={!opened ? style.main + " " + style.closed : style.main}>
      <p className={isError ? style.red : style.green}>{text}</p>
    </div>
  );
};

export default InfoMessage;
