import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AuthType } from "../../utils/types";

const initialState: {
  authData: AuthType;
  unauthorized: boolean;
  isLogouting: boolean;
} = {
  authData: {
    access_level: 0,
    created: 0,
    token: "",
    login: "",
    worker_name: "",
  },
  unauthorized: false,
  isLogouting: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthData(state, action: PayloadAction<AuthType>) {
      state.authData = action.payload;
    },
    setUnauthorized(state, action: PayloadAction<boolean>) {
      state.unauthorized = action.payload;
    },
    setLogouting(state, action: PayloadAction<boolean>) {
      state.isLogouting = action.payload;
    },
  },
});

export const { setAuthData, setUnauthorized, setLogouting } = authSlice.actions;

export default authSlice.reducer;
