import React, { useState, useRef, FC, DragEvent, ChangeEvent } from "react";
import Papa from "papaparse";

import style from "./FileInput.module.scss";

import { LabelsSendType } from "../utils/types";

interface IFileInput {
  file: File | undefined;
  setFile: (val: File) => void;
  setCSVData: (val: LabelsSendType[]) => void;
}

const FileInput: FC<IFileInput> = ({ file, setFile, setCSVData }) => {
  const [isDragOver, setIsDragOver] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const parseCSV = (file: File) => {
    if (!file) return;
    Papa.parse(file, {
      complete: function (results: any) {
        const result: LabelsSendType[] = results.data.map((item: any) => {
          return {
            importer: item[0],
            rus_name: item[1],
            barcode: item[2],
            gtin: item[3],
            brand: item[4],
            number_caption: item[5],
            size: item[6],
            color: item[7],
            cert_sign: item[8],
            data_matrix_code: item[9],
            data_matrix_gs1: item[10],
            composition: item[11],
            gender: item[12],
            date: item[13],
            producer: item[14],
          };
        });
        result.shift();
        setCSVData(result.filter((el) => el.barcode));
      },
    });
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLive = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(false);
    if (event.dataTransfer.files.length > 0) {
      setFile(event.dataTransfer.files[0]);
      parseCSV(event.dataTransfer.files[0]);
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
      parseCSV(event.target.files[0]);
    }
  };

  return (
    <div
      className={isDragOver ? `${style.box} ${style.onDrag}` : style.box}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLive}
      onDrop={handleDrop}
      onClick={() => inputRef.current?.click()}
    >
      {file ? (
        <p>SELECTED FILE: {file.name}</p>
      ) : (
        <p>SELECT A FILE OR DROP IT HERE</p>
      )}
      <input ref={inputRef} type="file" onChange={handleFileChange} />
    </div>
  );
};

export default FileInput;
