import { configureStore } from "@reduxjs/toolkit";

import clientsSlice from "./slices/clientsSlice";
import authSlice from "./slices/authSlice";
import ordersSlice from "./slices/ordersSlice";
import labelsSlice from "./slices/labelsSlice";
import jspmSlice from "./slices/jspmSlice";

export const store = configureStore({
  reducer: {
    jspm: jspmSlice,
    auth: authSlice,
    orders: ordersSlice,
    labels: labelsSlice,
    clients: clientsSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
