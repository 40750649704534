import { FC } from "react";
import { useAppSelector } from "../../redux/hooks";

import style from "./JspmInputs.module.scss";

interface IJspmInputs {
  isTesting: boolean;
  selectedPrinter: string;
  selectedPaper: string;
  selectedLabel: string;
  copies: string;
  printCount: string;
  setSelectedPrinter: (val: string) => void;
  setSelectedPaper: (val: string) => void;
  setSelectedLabel: (val: string) => void;
  setCopies: (val: string) => void;
  setPrintCount: (val: string) => void;
}

const JspmInputs: FC<IJspmInputs> = ({
  isTesting,
  selectedPrinter,
  selectedPaper,
  selectedLabel,
  copies,
  printCount,
  setSelectedPrinter,
  setSelectedPaper,
  setSelectedLabel,
  setCopies,
  setPrintCount,
}) => {
  const printers = useAppSelector((state) => state.jspm.printers);

  return (
    <>
      <div className={style.box}>
        <label className={style.label}>
          LABEL TYPE
          <select
            value={selectedLabel}
            onChange={(e) => setSelectedLabel(e.target.value)}
          >
            <option value={`58mm x 39mm (2.28" x 1.53")`}>
              58mm x 39mm (2.28" x 1.53")
            </option>
          </select>
        </label>
      </div>
      <div className={style.box}>
        <label className={style.label}>
          PRINTER
          <select
            value={selectedPrinter}
            onChange={(e) => setSelectedPrinter(e.target.value)}
          >
            <option value="" disabled>
              Select printer...
            </option>
            {printers.map((el, index) => (
              <option key={el.name + index}>{el.name}</option>
            ))}
          </select>
        </label>
      </div>
      <div className={style.box}>
        <label className={style.label}>
          PAPER SIZE
          <select
            value={selectedPaper}
            onChange={(e) => setSelectedPaper(e.target.value)}
          >
            <option value="" disabled>
              Select paper size...
            </option>
            {selectedPrinter &&
              printers
                .find((el) => el.name === selectedPrinter)
                .papers.map((el: string, index: number) => (
                  <option key={el + index}>{el}</option>
                ))}
          </select>
        </label>
      </div>
      <div className={style.box}>
        <label className={style.label}>
          COPIES
          <input
            type="number"
            value={copies}
            style={{ paddingLeft: "15px", textAlign: "center" }}
            onChange={(e) => setCopies(e.target.value)}
          />
        </label>
        <label
          className={style.label}
          style={isTesting ? { display: "none" } : {}}
        >
          PRINT COUNT
          <input
            type="number"
            value={printCount}
            style={{ paddingLeft: "15px", textAlign: "center" }}
            onChange={(e) => setPrintCount(e.target.value)}
          />
        </label>
      </div>
    </>
  );
};
export default JspmInputs;
