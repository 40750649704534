import { FC } from "react";
import { useAppSelector } from "../../redux/hooks";

import style from "../ManagementPanel.module.scss";

import Pagination from "./../Pagination";
import { svgNewOrder } from "../../utils/svgBoxes";

interface IOrderManagementPanel {
  currentPage: number;
  setCurrentPage: (value: number) => void;
  onButtonHandler: (value: boolean) => void;
}

const OrderManagementPanel: FC<IOrderManagementPanel> = ({
  currentPage,
  setCurrentPage,
  onButtonHandler,
}) => {
  const orders = useAppSelector((state) => state.orders.orders);
  const accessLevel = useAppSelector(
    (state) => state.auth.authData.access_level
  );

  const addOrderbuttonHandler = () => {
    if (accessLevel < 1) return;
    onButtonHandler(true);
  };

  return (
    <div className={style.main}>
      <div
        className={`${style.redButton} ${style.Btn}`}
        onClick={addOrderbuttonHandler}
        style={accessLevel < 1 ? { visibility: "hidden" } : {}}
        hidden
      >
        {svgNewOrder("white")}
        <span>NEW ORDER</span>
      </div>
      <Pagination
        dataSize={orders.length}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
};

export default OrderManagementPanel;
