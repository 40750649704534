import { FC, useRef, useState } from "react";
import html2canvas from "html2canvas";
import {
  ClientPrintJob,
  FileSourceType,
  InstalledPrinter,
  PrintFile,
} from "jsprintmanager";

import style from "./StartPrinting.module.scss";

import ScanBarcode from "./ScanBarcode";

interface IStartPrinting {
  order_id: number | undefined;
  copies: string;
  printCount: string;
  selectedPaper: string;
  selectedPrinter: string;
  isCheckboxActive: boolean;
}

const StartPrinting: FC<IStartPrinting> = ({
  order_id,
  copies,
  printCount,
  selectedPaper,
  selectedPrinter,
  isCheckboxActive,
}) => {
  const componentRef = useRef<HTMLDivElement>(null);

  const [showScan, setShowScan] = useState(false);
  const [isImageGet, setIsImageGet] = useState(false);
  const [scanResult, setScanResult] = useState("");

  const [lastJobStatus, setLastJobStatus] = useState("");
  const [printJobTrace, setPrintJobTrace] = useState("");

  const logPrintJobTrace = (data: any) => {
    if (lastJobStatus !== JSON.stringify(data)) {
      setLastJobStatus(JSON.stringify(data));
      let buffer = printJobTrace;
      setPrintJobTrace(buffer + "> " + lastJobStatus + "\r\n");
    }
  };

  const getPNG = async () => {
    try {
      const componentElement = componentRef.current;

      if (componentElement) {
        const canvas = await html2canvas(componentElement, {
          scale: 5,
          useCORS: true,
          logging: false,
        });
        const imgData = canvas.toDataURL("image/png");
        const blob = await (await fetch(imgData)).blob();
        const file = new File([blob], "component.png", { type: "image/png" });
        return file;
      }
    } catch (error) {
      console.log("Error capturing PNG:", error);
    }
  };

  const createPrintJob = async () => {
    const printFile = await getPNG();

    const WIDTH = 58 / 25.4;
    const HEIGHT = 39 / 25.4;
    const filename = `MyPicture-PX=0.03-PY=0.03-PW=${WIDTH}-PH=${HEIGHT}-PO=P.png`;
    const cpj = new ClientPrintJob();
    const printer = new InstalledPrinter(
      selectedPrinter,
      false,
      "",
      selectedPaper
    );

    cpj.clientPrinter = printer;

    if (printFile) {
      const my_file = new PrintFile(
        printFile,
        FileSourceType.BLOB,
        filename,
        +copies
      );
      cpj.files.push(my_file);
    }

    cpj.onUpdated = function (data) {
      logPrintJobTrace(data);
    };
    cpj.onFinished = function (data) {
      logPrintJobTrace(data);
      setScanResult("");
    };
    cpj.onError = function (data) {
      logPrintJobTrace(data);
    };
    return cpj;
  };

  const onStartPrintingHandler = async () => {
    const cpj = await createPrintJob();
    if (cpj) {
      cpj.sendToClient();
      setIsImageGet(true);
    }
  };

  return (
    <>
      <button
        className={`${style.button} ${
          isCheckboxActive && selectedPrinter && selectedPaper
            ? ""
            : style.disabledBtn
        }`}
        style={{ letterSpacing: "0.3em" }}
        onClick={() => {
          setShowScan(true);
          setScanResult("");
        }}
        disabled={!isCheckboxActive || !selectedPrinter || !selectedPaper}
      >
        START PRINTING
      </button>
      {showScan && (
        <ScanBarcode
          printCount={printCount}
          order_id={order_id}
          scanResult={scanResult}
          isImageGet={isImageGet}
          componentRef={componentRef}
          setShowScan={setShowScan}
          setScanResult={setScanResult}
          setIsImageGet={setIsImageGet}
          onStartPrintingHandler={onStartPrintingHandler}
        />
      )}
    </>
  );
};

export default StartPrinting;
