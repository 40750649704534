import { FC, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { FieldError, useForm } from "react-hook-form";

import style from "./NewClient.module.scss";

import { NewClientType } from "../../utils/types";
import { fetchAddClient } from "../../utils/requests";
import {
  svgEmail,
  svgNewClientWindow,
  svgPerson,
  svgPhone,
} from "../../utils/svgBoxes";
import { getClientsData } from "../../redux/slices/clientsSlice";
import { setUnauthorized } from "../../redux/slices/authSlice";
import { emailRegex, nameRegex, phoneRegex } from "../../utils/helpers";

interface INewClient {
  setAddClientClicked: (value: boolean) => void;
  setIsNewClientAddedMsg: (value: boolean) => void;
}

const NewClient: FC<INewClient> = ({
  setAddClientClicked,
  setIsNewClientAddedMsg,
}) => {
  const authData = useAppSelector((state) => state.auth.authData);

  const [isloading, setLoading] = useState(false);

  const {
    getValues,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<NewClientType>();

  const dispatch = useAppDispatch();

  const onSubmit = async () => {
    setLoading(true);
    try {
      const client = getValues();
      const response = await fetchAddClient(client, authData.token);
      if (response.ok) {
        setAddClientClicked(false);
        dispatch(getClientsData(authData));
        setIsNewClientAddedMsg(true);
      } else if (response.status === 401) {
        localStorage.removeItem("authData");
        dispatch(setUnauthorized(true));
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const formItem = (
    title: string,
    field: "name" | "email" | "phone",
    errors: FieldError | undefined,
    svg: (val: string) => JSX.Element,
    pattern: RegExp
  ) => {
    return (
      <label>
        <p className={style.inputTitle}>{title}</p>
        <div className={style.svgBox}>{svg("#0F3C81")}</div>
        <input
          className={errors ? inputStyle : style.input}
          type="text"
          {...register(field, { required: true, pattern })}
        />
      </label>
    );
  };

  const inputStyle = `${style.input} ${style.inputWrong}`;

  return (
    <>
      <div className={style.main}>
        <div className={style.window}>
          <form action="" onSubmit={handleSubmit(onSubmit)}>
            <div className={style.titleBox}>
              {svgNewClientWindow("white")}
              <label className={style.title}>NEW CLIENT</label>
            </div>
            {formItem("NAME", "name", errors.name, svgPerson, nameRegex)}
            {formItem("EMAIL", "email", errors.email, svgEmail, emailRegex)}
            {formItem("PHONE", "phone", errors.phone, svgPhone, phoneRegex)}
            {isloading ? (
              <div className={style.button}>
                <div className={style.wloader}></div>
              </div>
            ) : (
              <input className={style.button} type="submit" value="ADD" />
            )}
            <div
              onClick={() => setAddClientClicked(false)}
              className={style.close}
            >
              <span className={style.one}></span>
              <span className={style.two}></span>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default NewClient;
