import { FC } from "react";

import style from "./JspmNotWorking.module.scss";

import JspmStatusBox from "./JspmStatusBox";

const JspmNotWorking: FC = () => {
  return (
    <div className={style.main}>
      <JspmStatusBox />
      <div className={style.jspmMessage}>
        JavaScript Print Manager (JSPM) is either not installed or not currently
        running. This software is required to enable printing functionality from
        the client. Please install or start JSPM, then restart your browser to
        proceed with printing.
      </div>
      <a
        rel="noreferrer"
        href="https://www.neodynamic.com/downloads/jspm/"
        target="_blank"
        style={{ textDecoration: "none" }}
      >
        <button className={style.button} style={{ letterSpacing: "0.3em" }}>
          DOWNLOAD JSPM
        </button>
      </a>
    </div>
  );
};

export default JspmNotWorking;
