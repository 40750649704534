import { useNavigate } from "react-router-dom";
import { FC, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";

import style from "./AdminPanel.module.scss";

import MainTitle from "../components/MainTitle";
import Labels from "../components/Labels/Labels";
import UnaccLabels from "../components/Labels/UnaccLabels";
import { LabelResponseType, UnaccLabelResponseType } from "../utils/types";
import {
  getLabelsByOrder,
  getUnaccLabelsByOrder,
} from "../redux/slices/labelsSlice";

const AdminPanel: FC<{ mainTitle: string }> = ({ mainTitle }) => {
  const authData = useAppSelector((state) => state.auth.authData);
  const {
    labels,
    unaccLabels,
    isLoadingLabels,
    isLoadingUnaccLabels,
    currentOrderID,
    currentOrderName,
  } = useAppSelector((state) => state.labels);

  const [isUnaccLabels, setUnaccLabels] = useState(false);

  const [localLabels, setLocalLabels] = useState<Array<LabelResponseType>>([]);
  const [localUnaccLabels, setLocalUnaccLabels] = useState<
    Array<UnaccLabelResponseType>
  >([]);

  const isResponse = useRef(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isResponse.current) {
      isResponse.current = true;
      dispatch(getLabelsByOrder({ token: authData.token, id: currentOrderID }));
      dispatch(
        getUnaccLabelsByOrder({ token: authData.token, id: currentOrderID })
      );
    }
    isResponse.current = true;
  }, [authData.token, currentOrderID, dispatch]);

  useEffect(() => {
    setLocalLabels(labels);
    setLocalUnaccLabels(unaccLabels);
  }, [labels, unaccLabels]);

  useEffect(() => {
    if (authData.access_level < 1) navigate("/orders");
  }, [navigate, authData]);

  useEffect(() => {
    document.title = "Label Matrix | Admin Panel";
  }, []);

  if (isLoadingUnaccLabels || isLoadingLabels) {
    return (
      <div className={style.loaderBox}>
        <div className={style.loader}></div>
      </div>
    );
  }

  return (
    <div className={style.main}>
      <MainTitle title={mainTitle} />
      <div className={style.infoOrderBox}>
        <div className={style.info}>{`ORDER № ${currentOrderID}`}</div>
        <div className={style.info}>{currentOrderName}</div>
      </div>
      {isUnaccLabels ? (
        <UnaccLabels
          unaccLabels={unaccLabels}
          localUnaccLabels={localUnaccLabels}
          setLocalUnaccLabels={setLocalUnaccLabels}
          setUnaccLabels={setUnaccLabels}
        />
      ) : (
        <Labels
          labels={labels}
          localLabels={localLabels}
          setLocalLabels={setLocalLabels}
          setUnaccLabels={setUnaccLabels}
        />
      )}
    </div>
  );
};

export default AdminPanel;
