import { FC } from "react";
import { WSStatus } from "jsprintmanager";
import { useAppSelector } from "../../redux/hooks";

import style from "./OrderDetails.module.scss";

import JspmWorking from "../JSPM/JspmWorking";
import JspmNotWorking from "../JSPM/JspmNotWorking";
import { svgBackArrow } from "../../utils/svgBoxes";
import { OrderResponseType } from "../../utils/types";

interface IOrderDetails {
  currentOrder: OrderResponseType | undefined;
  setDetailsOrderClecked: (val: boolean) => void;
}

const OrderDetails: FC<IOrderDetails> = ({
  currentOrder,
  setDetailsOrderClecked,
}) => {
  const jspmStatus = useAppSelector((state) => state.jspm.jspmStatus);

  const jspmContent = () => {
    if (jspmStatus === WSStatus.Open) {
      return <JspmWorking order={currentOrder} />;
    } else if (jspmStatus === WSStatus.WaitingForUserResponse) {
      return (
        <div className={style.loaderBox}>
          <div className={style.loader}></div>
        </div>
      );
    } else {
      return <JspmNotWorking />;
    }
  };

  return (
    <div className={style.wrapper}>
      <button
        onClick={() => setDetailsOrderClecked(false)}
        className={`${style.button} ${style.backButton}`}
      >
        {svgBackArrow("white")}
        BACK
      </button>
      {jspmContent()}
    </div>
  );
};

export default OrderDetails;
