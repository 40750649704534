import { FC, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

import style from "./EditOrder.module.scss";

import { setOrders } from "../../redux/slices/ordersSlice";
import { fetchChangeOrderStatus } from "../../utils/requests";
import { setUnauthorized } from "../../redux/slices/authSlice";
import { OrderResponseType, OrderStatusType } from "../../utils/types";

const status: Array<OrderStatusType> = [
  "FINISHED",
  "RECEIVED",
  "PENDING",
  "PROCESSING",
];

interface IEditOrder {
  order: OrderResponseType;
  setEditOrder: (val: boolean) => void;
}

const EditOrder: FC<IEditOrder> = ({ order, setEditOrder }) => {
  const authData = useAppSelector((state) => state.auth.authData);
  const orders = useAppSelector((state) => state.orders.orders);

  const [newStatus, setNewStatus] = useState("FINISHED");
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [notFound, setNotFound] = useState(false);

  const dispatch = useAppDispatch();

  const onClickChangeStatus = async () => {
    try {
      if (authData) {
        setError(false);
        setNotFound(false);
        setLoading(true);
        const response = await fetchChangeOrderStatus(
          newStatus,
          order.id,
          authData.token
        );
        if (response.ok) {
          setEditOrder(false);
          const newArr = orders.map((el) =>
            el.id === order.id ? { ...el, status: newStatus } : el
          );
          dispatch(setOrders(newArr));
        } else if (response.status === 401) {
          localStorage.removeItem("authData");
          dispatch(setUnauthorized(true));
        } else if (response?.status === 404) {
          setNotFound(true);
        }
      }
    } catch (error) {
      setError(true);
    }
    setLoading(false);
  };

  const statusStyle =
    order.status === "PROCESSING" ? style.processingStatus : "";

  return (
    <>
      <div className={style.main}>
        <div className={style.window}>
          <div className={style.info}>
            <span>{`ORDER № ${order.id}`}</span>
            <span>{order.order_name}</span>
            <span>
              STATUS:
              <p className={statusStyle}>{order.status}</p>
            </span>
          </div>
          <span>NEW STATUS</span>
          <select
            value={newStatus}
            onChange={(e) => setNewStatus(e.target.value)}
          >
            {status
              .filter((el) => el !== order.status)
              .map((el, index) => (
                <option key={index}>{el}</option>
              ))}
          </select>
          {isLoading ? (
            <div className={style.disableLoaderBtn}>
              <div className={style.wloader}></div>
            </div>
          ) : (
            <button onClick={onClickChangeStatus}>CHANGE STATUS</button>
          )}
          <div onClick={() => setEditOrder(false)} className={style.close}>
            <span className={style.one}></span>
            <span className={style.two}></span>
          </div>
          {isError && (
            <span className={style.errorMessage}>Server connection error!</span>
          )}
          {notFound && (
            <span className={style.errorMessage}>Order not found!</span>
          )}
        </div>
      </div>
    </>
  );
};

export default EditOrder;
