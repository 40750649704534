import { FC, KeyboardEvent, useEffect, useState } from "react";

import style from "./Pagination.module.scss";

import { ITEMS_COUNT } from "../utils/helpers";

enum Sign {
  plus = "+",
  minus = "-",
}

interface IPagination {
  dataSize: number;
  currentPage: number;
  setCurrentPage: (value: number) => void;
}

const Pagination: FC<IPagination> = ({
  dataSize,
  currentPage,
  setCurrentPage,
}) => {
  const [inputPage, setInputPage] = useState(currentPage.toString());

  const onChangePage = (sign: Sign) => {
    if (sign === Sign.plus) {
      if (Math.ceil(dataSize / ITEMS_COUNT) > currentPage) {
        setCurrentPage(currentPage + 1);
      }
    } else {
      if (currentPage > 1) setCurrentPage(currentPage - 1);
    }
  };

  const newPageHandler = () => {
    const page = +inputPage;
    if (isNaN(page)) {
      return;
    }
    if (page < 1) {
      setCurrentPage(1);
    } else if (page > Math.ceil(dataSize / ITEMS_COUNT) + 1) {
      setCurrentPage(Math.ceil(dataSize / ITEMS_COUNT));
    } else {
      setCurrentPage(page);
    }
  };

  useEffect(() => {
    setInputPage(currentPage.toString());
  }, [currentPage]);

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") newPageHandler();
  };

  let prevBtnStyle = style.prevNext;
  let nextBtnStyle = style.prevNext;

  if (currentPage === 1) prevBtnStyle += ` ${style.nonActive}`;
  if (Math.ceil(dataSize / ITEMS_COUNT) <= currentPage)
    nextBtnStyle += ` ${style.nonActive}`;

  return (
    <div className={style.paginationBox}>
      <div className={prevBtnStyle} onClick={() => onChangePage(Sign.minus)}>
        PREV
      </div>
      <input
        className={style.currentPage}
        type="text"
        value={inputPage}
        onChange={(e) => setInputPage(e.target.value)}
        onKeyDown={handleKeyDown}
        onBlur={newPageHandler}
      />
      <div className={nextBtnStyle} onClick={() => onChangePage(Sign.plus)}>
        NEXT
      </div>
    </div>
  );
};

export default Pagination;
