import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

import style from "../Clients/NewClient.module.scss";

import FileInput from "../FileInput";
import {
  LabelResponseType,
  LabelsSendType,
  OrderResponseType,
} from "../../utils/types";
import { fetchAddLabelsToOrder } from "../../utils/requests";
import { setUnauthorized } from "../../redux/slices/authSlice";
import { svgNewLabel } from "../../utils/svgBoxes";
import { getOrdersData } from "../../redux/slices/ordersSlice";

interface INewLabelsToOrder {
  order: OrderResponseType;
  setAddLabelWindow: (val: boolean) => void;
  setIsNewLabelsAddedMsg: (val: boolean) => void;
  setIsAddLabelsError: (val: boolean) => void;
}

const NewLabelsToOrder: FC<INewLabelsToOrder> = ({
  order,
  setAddLabelWindow,
  setIsNewLabelsAddedMsg,
  setIsAddLabelsError,
}) => {
  const authData = useAppSelector((state) => state.auth.authData);

  const [file, setFile] = useState<File>();
  const [csvData, setCSVData] = useState<LabelsSendType[]>([]);

  const dispatch = useAppDispatch();

  const onSubmit = async () => {
    try {
      const response = await fetchAddLabelsToOrder(
        csvData,
        order.id,
        authData.token
      );
      if (response.ok) {
        setAddLabelWindow(false);
        setIsNewLabelsAddedMsg(true);
        dispatch(getOrdersData(authData));
      } else if (response.status === 401) {
        localStorage.removeItem("authData");
        dispatch(setUnauthorized(true));
      } else {
        setIsAddLabelsError(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const { handleSubmit } = useForm<LabelResponseType>();

  return (
    <>
      <div className={style.main}>
        <div className={style.window}>
          <form action="" onSubmit={handleSubmit(onSubmit)}>
            <div className={style.titleBox}>
              <label className={style.title} style={{ flexDirection: "row" }}>
                <div
                  style={{
                    display: "inline",
                    width: "100px",
                    translate: "15px 3px",
                  }}
                >
                  {svgNewLabel("white")}
                </div>
                ADD LABELS
              </label>
            </div>
            <div>
              <label style={{ marginBottom: "10px" }}>CSV FILE</label>
              <FileInput
                file={file}
                setFile={setFile}
                setCSVData={setCSVData}
              />
            </div>
            <input className={style.button} type="submit" value="ADD" />
            <div
              onClick={() => setAddLabelWindow(false)}
              className={style.close}
            >
              <span className={style.one}></span>
              <span className={style.two}></span>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default NewLabelsToOrder;
