import { FC, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/hooks";

import style from "./SideBar.module.scss";

import * as svg from "../utils/svgBoxes";
import { setAuthData, setLogouting } from "../redux/slices/authSlice";

const mainColor = "#0F3C81";
const whiteColor = "#FFFFFF";

const SideBar: FC<{ username: string | null }> = ({ username }) => {
  const authData = useAppSelector((state) => state.auth.authData);

  const [isCollapsed, setIsCollapsed] = useState(false);

  const loc = useLocation().pathname;

  const dispatch = useAppDispatch();

  const styleNavBtn = `${style.navButton} ${style.navButtonActive}`;

  const styleCollapsed = (style: string, collapsedStyle: string) => {
    return isCollapsed ? `${style} ${collapsedStyle}` : style;
  };

  const sideBarbtn = (path: string, title: string, svgFn: svg.svgFnType) => {
    return (
      <Link to={path}>
        <div
          className={
            loc === path
              ? styleCollapsed(styleNavBtn, style.navButtonColl)
              : styleCollapsed(style.navButton, style.navButtonColl)
          }
        >
          <div className={style.svgBox}>
            {svgFn(loc === path ? whiteColor : mainColor)}
          </div>
          {!isCollapsed && <div>{title}</div>}
        </div>
      </Link>
    );
  };

  const onClickLogoutHandler = () => {
    dispatch(setLogouting(true));
    dispatch(
      setAuthData({
        access_level: 0,
        created: 0,
        token: "",
        login: "",
        worker_name: "",
      })
    );
    localStorage.removeItem("authData");
    window.location.reload();
  };

  return (
    <aside className={styleCollapsed(style.aside, style.asideColl)}>
      <button
        className={styleCollapsed(style.collapseBtn, style.collapseBtnColl)}
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        {svg.svgCollapseArrow("#0f3c81")}
      </button>
      <div className={styleCollapsed(style.logoBox, style.logoBoxColl)}>
        {isCollapsed ? svg.svgLogo() : svg.svgLogoWithName()}
      </div>
      <div className={style.btnBlock}>
        {sideBarbtn("/orders", "ORDERS", svg.svgOrder)}
        {sideBarbtn("/print-test", "PRINT TEST", svg.svgPrintTest)}
        {authData.access_level > 0 &&
          sideBarbtn("/clients", "CLIENTS", svg.svgClients)}
      </div>
      <div className={style.bottomBox}>
        <div
          className={styleCollapsed(
            `${style.navButton} ${style.loginUserLabel}`,
            style.loginUserLabelCall
          )}
        >
          {svg.svgUser(mainColor)}
          <div>{!isCollapsed && username}</div>
        </div>
        <div
          onClick={onClickLogoutHandler}
          className={styleCollapsed(
            `${style.navButton} ${style.logoutBtn}`,
            style.logoutBtnCall
          )}
        >
          <div className={style.svgBox}>{svg.svgLogout(mainColor)}</div>
          {!isCollapsed && <div>LOG OUT</div>}
        </div>
      </div>
    </aside>
  );
};

export default SideBar;
