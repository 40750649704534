import { LabelResponseType } from "./types";

export const DELAY = 300;
export const ITEMS_COUNT = 12;
export const emailRegex: RegExp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const phoneRegex: RegExp =
  /^\+?\d{1,3}?[-. ]?\(?\d{1,3}\)?[-. ]?\d{3}[-. ]?\d{4}$/;
export const nameRegex: RegExp = /^[\s\S]+$/;

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${day}.${month}.${year}`;
};

export const paginate = <T>(data: Array<T>, page: number): Array<T> => {
  return data.filter(
    (_, index) =>
      index < ITEMS_COUNT * page && index >= ITEMS_COUNT * (page - 1)
  );
};

export const testLabel: LabelResponseType = {
  id: 1,
  order_id: 1,
  importer:
    "АО «СТОКМАНН», Краснопресненская наб, 12, оф 1001, 123610, Москва, РФ",
  rus_name: "HM4975 мяч Adidas (Оранжевый) (7)",
  barcode: "4065429215738",
  gtin: "4660209477025",
  brand: "Adidas",
  number_caption: "312611582",
  size: "7 (RU 39)",
  color: "basketball natural",
  cert_sign: "eac",
  data_matrix_code: "46602094770255jsiCo!7Zndjz",
  data_matrix_gs1:
    "&#232;0104660209477025215jsiCo!7Zndjz&#232;9100C0&#232;92XkOSkqCXYpXNCFlGqDBqNl0Usp9qMcR3miL9erp72a+0YoEFdAznsJLxY34cBjp1XcZdUiL1hOZzOke752HLCA==",
  composition: "Полиуретан-100%",
  gender: "УНИСЕКС",
  date: "01.2024",
  producer: "adidas International Trading AG Platz 1b, 6039 Root D4, Швейцария",
  status: "PRINTED",
  created_at: "2024-07-12T11:54:38.247089Z",
  updated_at: null,
  updated_by: null,
};
