import { FC, useState } from "react";

import style from "../Orders/OrderDetails.module.scss";

import JspmInputs from "./JspmInputs";
import JspmStatusBox from "./JspmStatusBox";
import StartPrinting from "./StartPrinting";
import { useAppSelector } from "../../redux/hooks";
import { OrderResponseType } from "../../utils/types";

interface IJspmWorking {
  order: OrderResponseType | undefined;
}

const JspmWorking: FC<IJspmWorking> = ({ order }) => {
  const isLoading = useAppSelector((state) => state.jspm.isLoading);

  const [isCheckboxActive, setCheckboxActive] = useState(false);
  const [selectedPrinter, setSelectedPrinter] = useState("");
  const [selectedPaper, setSelectedPaper] = useState("");
  const [selectedLabel, setSelectedLabel] = useState("");
  const [copies, setCopies] = useState("1");
  const [printCount, setPrintCount] = useState("1");

  return (
    <div className={style.main}>
      <div className={style.title}>
        {order && (
          <>
            <span>ORDER № {order.id}</span>
            <span>{order.order_name}</span>
          </>
        )}
      </div>
      <JspmStatusBox />
      <span style={{ paddingLeft: "70px" }}>
        <div
          onClick={() => setCheckboxActive(!isCheckboxActive)}
          className={style.checkbox}
        >
          {isCheckboxActive && (
            <>
              <div className={style.one}></div>
              <div className={style.two}></div>
            </>
          )}
        </div>
        <span>
          I checked <p>Scanner</p> and <p>Printer</p>
        </span>
      </span>
      <JspmInputs
        isTesting={false}
        copies={copies}
        setCopies={setCopies}
        selectedLabel={selectedLabel}
        setSelectedLabel={setSelectedLabel}
        selectedPaper={selectedPaper}
        setSelectedPaper={setSelectedPaper}
        selectedPrinter={selectedPrinter}
        setSelectedPrinter={setSelectedPrinter}
        printCount={printCount}
        setPrintCount={setPrintCount}
      />
      <StartPrinting
        order_id={order?.id}
        copies={copies}
        printCount={printCount}
        selectedPrinter={selectedPrinter}
        selectedPaper={selectedPaper}
        isCheckboxActive={isCheckboxActive}
      />
      {isLoading && (
        <div className={style.background}>
          <div>Getting printers info...</div>
          <div className={style.wloader}></div>
        </div>
      )}
    </div>
  );
};

export default JspmWorking;
