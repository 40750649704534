import { FC } from "react";

import style from "../ManagementPanel.module.scss";

import { svgBackArrow } from "../../utils/svgBoxes";

interface IAdminManagementPanel {
  isLabel: boolean;
  onButtonHandler: (value: boolean) => void;
}

const AdminManagementPanel: FC<IAdminManagementPanel> = ({
  isLabel,
  onButtonHandler,
}) => {
  return (
    <div className={style.main}>
      {isLabel ? (
        <div
          className={`${style.redButton} ${style.Btn}`}
          onClick={() => onButtonHandler(true)}
        >
          <span>UNACCOUNTED GOODS</span>
        </div>
      ) : (
        <div
          onClick={() => onButtonHandler(false)}
          className={`${style.redButton} ${style.Btn}`}
        >
          {svgBackArrow("white")}
          <span>BACK</span>
        </div>
      )}
      <div style={{ maxWidth: " 300px", width: " 300px" }}></div>
    </div>
  );
};

export default AdminManagementPanel;
