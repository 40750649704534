import bwipjs from "bwip-js";
import Barcode from "react-barcode";
import { FC, useEffect, useState } from "react";

import style from "./LabelPrint.module.scss";

import { LabelResponseType } from "../../utils/types";
import eacImg from "../../assets/img/eac.png";

interface ILabelPrint {
  label: LabelResponseType | undefined;
  setLabelMounted: (val: boolean) => void;
}

const LabelPrint: FC<ILabelPrint> = ({ label, setLabelMounted }) => {
  const [src, setImageSrc] = useState("");

  useEffect(() => {
    if (!label) return;
    if (label.data_matrix_code && label.data_matrix_gs1) {
      let canvas = document.createElement("canvas");
      // @ts-ignore
      bwipjs.toCanvas(canvas, {
        bcid: "datamatrix",
        text: label.data_matrix_gs1,
        scale: 1,
        height: 80,
        width: 80,
      });
      setImageSrc(canvas.toDataURL("image/png"));
    }
    setLabelMounted(true);
  }, [label, setLabelMounted]);

  if (!label) return <></>;

  const isDataMatrix =
    label.data_matrix_code && label.data_matrix_gs1 ? true : false;

  return (
    <div className={style.card}>
      {isDataMatrix && (
        <>
          <div className={style.head}>
            <div className={style.dataBox}>
              <img src={src} alt="data-matrix-code" />
            </div>
            <div>Импортер: {label.importer}</div>
          </div>
          <div className={style.code}>{label.data_matrix_code}</div>
        </>
      )}
      <div className={style.core}>
        <div className={style.twoLine}>{label.rus_name}</div>
        <div className={style.oneLine}>Бренд: {label.brand}</div>
        <div className={style.oneLine}>Р-р: {label.size}</div>
        <div className={style.compAndSert}>
          <div className={style.twoLine}>Состав: {label.composition}</div>
          <div className={style.eacBox}>
            <img src={eacImg} alt="" />
          </div>
        </div>
        <div className={style.oneLine}>Дата пр-ва: {label.date}</div>
      </div>
      <div className={style.threeLine}>Производитель: {label.producer}</div>
      {!isDataMatrix && (
        <div className={style.threeLine}>Импортер: {label.importer}</div>
      )}
      <div className={style.barBox}>
        <Barcode
          value={label.barcode}
          width={1}
          height={20}
          fontSize={9}
          font="Segoe UI, Tahoma, Geneva, Verdana, sans-serif"
        />
      </div>
    </div>
  );
};

export default LabelPrint;
