import { FC, Fragment, useEffect, useState } from "react";

import style from "../../pages/AdminPanel.module.scss";

import LabelItem from "./LabelItem";
import AdminManagementPanel from "./AdminManagementPanel";
import { DELAY } from "../../utils/helpers";
import { useDebounce } from "../../utils/hooks";
import { UnaccLabelResponseType } from "../../utils/types";
import Sort from "../Sort";
import { setUnacLabels } from "../../redux/slices/labelsSlice";

const title = ["№", "Barcode", "Name", "Scanned At", "Scanned By", ""];

interface IUnaccLabels {
  unaccLabels: UnaccLabelResponseType[];
  localUnaccLabels: UnaccLabelResponseType[];
  setUnaccLabels: (value: boolean) => void;
  setLocalUnaccLabels: (value: UnaccLabelResponseType[]) => void;
}

const UnaccLabels: FC<IUnaccLabels> = ({
  unaccLabels,
  localUnaccLabels,
  setLocalUnaccLabels,
  setUnaccLabels,
}) => {
  const [activeIndexTitle, setActiveIndexTitle] = useState("");

  const [titleID, setTitleID] = useState("");
  const [titleBarcode, setTitleBarcode] = useState("");
  const [titleName, setTitleName] = useState("");
  const [titleScannedAt, setTitleScannedAt] = useState("");
  const [titleScannedBy, setTitleScannedBy] = useState("");

  const debouncedTitleID = useDebounce(titleID, DELAY);
  const debouncedTitleBarcode = useDebounce(titleBarcode, DELAY);
  const debouncedTitleName = useDebounce(titleName, DELAY);
  const debouncedTitleScannedAt = useDebounce(titleScannedAt, DELAY);
  const debouncedTitleScannedBy = useDebounce(titleScannedBy, DELAY);

  useEffect(() => {
    const filterScanned_at = (label: UnaccLabelResponseType) => {
      if (label.scanned_at) {
        return (
          !label.scanned_at ||
          label.scanned_at.includes(debouncedTitleScannedAt)
        );
      }
      return debouncedTitleScannedAt === "-" || debouncedTitleScannedAt === "";
    };
    const filterScanned_by = (label: UnaccLabelResponseType) => {
      if (label.scanned_by && typeof label.scanned_by === "string") {
        return (
          !label.scanned_by ||
          label.scanned_by
            .toLowerCase()
            .includes(debouncedTitleScannedBy.toLowerCase())
        );
      }
      return debouncedTitleScannedBy === "-" || debouncedTitleScannedBy === "";
    };
    const result = unaccLabels.filter(
      (label) =>
        (!label.id || label.id.toString().includes(debouncedTitleID)) &&
        (!label.barcode || label.barcode.includes(debouncedTitleBarcode)) &&
        (!label.rus_name ||
          label.rus_name
            .toLowerCase()
            .includes(debouncedTitleName.toLowerCase())) &&
        filterScanned_at(label) &&
        filterScanned_by(label)
    );
    setLocalUnaccLabels(result);
  }, [
    debouncedTitleID,
    debouncedTitleBarcode,
    debouncedTitleName,
    debouncedTitleScannedAt,
    debouncedTitleScannedBy,
    unaccLabels,
    setLocalUnaccLabels,
  ]);

  const titleInput = (
    title: string,
    placeholder: string,
    index: string,
    setFn: (value: string) => void
  ) => {
    return (
      <div className={style.inputBox}>
        <input
          type="text"
          value={title}
          placeholder={placeholder}
          onChange={(e) => setFn(e.target.value)}
        />
        <Sort
          index={index}
          data={unaccLabels}
          setData={setUnacLabels}
          activeIndexTitle={activeIndexTitle}
          setActiveIndexTitle={setActiveIndexTitle}
        />
      </div>
    );
  };

  return (
    <>
      <div className={style.data}>
        <div className={`${style.dataTitle} ${style.dataUnaccTitle}`}>
          {titleInput(titleID, title[0], "id", setTitleID)}
          {titleInput(titleBarcode, title[1], "barcode", setTitleBarcode)}
          {titleInput(titleName, title[2], "rus_name", setTitleName)}
          {titleInput(
            titleScannedAt,
            title[3],
            "scanned_at",
            setTitleScannedAt
          )}
          {titleInput(
            titleScannedBy,
            title[4],
            "scanned_by",
            setTitleScannedBy
          )}
        </div>
        <div className={style.dataBox}>
          {localUnaccLabels.map((label, index) => (
            <Fragment key={index}>
              <LabelItem label={label} />
            </Fragment>
          ))}
        </div>
      </div>
      <AdminManagementPanel isLabel={false} onButtonHandler={setUnaccLabels} />
    </>
  );
};

export default UnaccLabels;
