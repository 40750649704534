import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as JSPM from "jsprintmanager";

const PORT = 27443;

export const getJspmStatus = createAsyncThunk(
  "jspm/getJspmStatus",
  async (_, { dispatch }) => {
    JSPM.JSPrintManager.auto_reconnect = true;
    await JSPM.JSPrintManager.start(true, "localhost", PORT);

    // @ts-ignore
    JSPM.JSPrintManager.WS.onError = (error) => {
      console.error(error);
    };

    if (JSPM.JSPrintManager.WS) {
      JSPM.JSPrintManager.WS.onOpen = () => {
        dispatch(setLoading(true));

        JSPM.JSPrintManager.getPrintersInfo(
          JSPM.PrintersInfoLevel.Basic,
          "",
          JSPM.PrinterIcon.None
        ).then((printersList: any) => {
          dispatch(setPrinters(printersList));
          dispatch(setLoading(false));
        });

        if (JSPM.JSPrintManager.WS) {
          dispatch(setJspmStatus(JSPM.JSPrintManager.WS.status));
        }
      };

      JSPM.JSPrintManager.WS.onStatusChanged = () => {
        if (JSPM.JSPrintManager.WS) {
          dispatch(setJspmStatus(JSPM.JSPrintManager.WS.status));
        }
      };

      JSPM.JSPrintManager.WS.onClose = () => {
        if (JSPM.JSPrintManager.WS) {
          dispatch(setJspmStatus(JSPM.JSPrintManager.WS.status));
        }
      };
    }
  }
);

interface initialStateI {
  printers: Array<any>;
  jspmStatus: JSPM.WSStatus;
  isLoading: boolean;
}

const initialState: initialStateI = {
  printers: [],
  jspmStatus: JSPM.WSStatus.WaitingForUserResponse,
  isLoading: false,
};

const jspmSlice = createSlice({
  name: "jspm",
  initialState,
  reducers: {
    setJspmStatus(state, action: PayloadAction<JSPM.WSStatus>) {
      state.jspmStatus = action.payload;
    },
    setPrinters(state, action: PayloadAction<string[]>) {
      state.printers = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
  },
});

export const { setJspmStatus, setPrinters, setLoading } = jspmSlice.actions;

export default jspmSlice.reducer;
