import { FC, Fragment, useEffect, useState } from "react";

import style from "../../pages/AdminPanel.module.scss";

import LabelItem from "./LabelItem";
import AdminManagementPanel from "./AdminManagementPanel";
import { DELAY } from "../../utils/helpers";
import { useDebounce } from "../../utils/hooks";
import { LabelResponseType } from "../../utils/types";
import Sort from "../Sort";
import { setLabels } from "../../redux/slices/labelsSlice";

const title = [
  "№",
  "Barcode",
  "Status",
  "Name",
  "Printed At",
  "Printed By",
  "",
];

interface ILabels {
  labels: LabelResponseType[];
  localLabels: LabelResponseType[];
  setLocalLabels: (value: LabelResponseType[]) => void;
  setUnaccLabels: (value: boolean) => void;
}

const Labels: FC<ILabels> = ({
  labels,
  localLabels,
  setLocalLabels,
  setUnaccLabels,
}) => {
  const [activeIndexTitle, setActiveIndexTitle] = useState("");

  const [titleID, setTitleID] = useState("");
  const [titleBarcode, setTitleBarcode] = useState("");
  const [titleStatus, setdTitleStatus] = useState("");
  const [titleName, setTitleName] = useState("");
  const [titlePrintedAt, setTitlePrintedAt] = useState("");
  const [titlePrintedBy, setTitlePrintedBy] = useState("");

  const debouncedTitleID = useDebounce(titleID, DELAY);
  const debouncedTitleBarcode = useDebounce(titleBarcode, DELAY);
  const debouncedTitleStatus = useDebounce(titleStatus, DELAY);
  const debouncedTitleName = useDebounce(titleName, DELAY);
  const debouncedTitlePrintedAt = useDebounce(titlePrintedAt, DELAY);
  const debouncedTitlePrintedBy = useDebounce(titlePrintedBy, DELAY);

  useEffect(() => {
    const filterUpdated_at = (label: LabelResponseType) => {
      if (label.updated_at) {
        return (
          !label.updated_at ||
          label.updated_at.includes(debouncedTitlePrintedAt)
        );
      }
      return debouncedTitlePrintedAt === "-" || debouncedTitlePrintedAt === "";
    };
    const filterUpdated_by = (label: LabelResponseType) => {
      if (label.updated_by && typeof label.updated_by === "string") {
        return (
          !label.updated_by ||
          label.updated_by
            .toLowerCase()
            .includes(debouncedTitlePrintedBy.toLowerCase())
        );
      }
      return debouncedTitlePrintedBy === "-" || debouncedTitlePrintedBy === "";
    };
    const result = labels.filter(
      (label) =>
        (!label.id || label.id.toString().includes(debouncedTitleID)) &&
        (!label.barcode || label.barcode.includes(debouncedTitleBarcode)) &&
        (!label.status ||
          label.status
            .toLowerCase()
            .includes(debouncedTitleStatus.toLowerCase())) &&
        (!label.rus_name ||
          label.rus_name
            .toLowerCase()
            .includes(debouncedTitleName.toLowerCase())) &&
        filterUpdated_at(label) &&
        filterUpdated_by(label)
    );
    setLocalLabels(result);
  }, [
    debouncedTitleID,
    debouncedTitleBarcode,
    debouncedTitleStatus,
    debouncedTitleName,
    debouncedTitlePrintedAt,
    debouncedTitlePrintedBy,
    labels,
    setLocalLabels,
  ]);

  const titleInput = (
    title: string,
    placeholder: string,
    index: string,
    setFn: (value: string) => void
  ) => {
    return (
      <div className={style.inputBox}>
        <input
          type="text"
          value={title}
          placeholder={placeholder}
          onChange={(e) => setFn(e.target.value)}
        />
        <Sort
          index={index}
          data={labels}
          setData={setLabels}
          activeIndexTitle={activeIndexTitle}
          setActiveIndexTitle={setActiveIndexTitle}
        />
      </div>
    );
  };

  return (
    <>
      <div className={style.data}>
        <div className={`${style.dataTitle}`}>
          {titleInput(titleID, title[0], "id", setTitleID)}
          {titleInput(titleBarcode, title[1], "barcode", setTitleBarcode)}
          {titleInput(titleStatus, title[2], "status", setdTitleStatus)}
          {titleInput(titleName, title[3], "rus_name", setTitleName)}
          {titleInput(
            titlePrintedAt,
            title[4],
            "updated_at",
            setTitlePrintedAt
          )}
          {titleInput(
            titlePrintedBy,
            title[5],
            "updated_by",
            setTitlePrintedBy
          )}
        </div>
        <div className={style.dataBox}>
          {localLabels.map((label, index) => (
            <Fragment key={index}>
              <LabelItem label={label} />
            </Fragment>
          ))}
        </div>
      </div>
      <AdminManagementPanel isLabel={true} onButtonHandler={setUnaccLabels} />
    </>
  );
};

export default Labels;
