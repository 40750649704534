import { FC, useState } from "react";

import style from "./EditLabel.module.scss";

import { LabelResponseType } from "../../utils/types";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { fetchChangeLabelStatus } from "../../utils/requests";
import { setLabels } from "../../redux/slices/labelsSlice";
import { setUnauthorized } from "../../redux/slices/authSlice";

interface IEditLabelStatus {
  label: LabelResponseType;
  setEditStatus: (val: boolean) => void;
}

const EditLabelStatus: FC<IEditLabelStatus> = ({ label, setEditStatus }) => {
  const authData = useAppSelector((state) => state.auth.authData);
  const labels = useAppSelector((state) => state.labels.labels);

  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [notFound, setNotFound] = useState(false);

  const dispatch = useAppDispatch();

  const onClickChangeStatus = async () => {
    try {
      if (authData) {
        setError(false);
        setNotFound(false);
        setLoading(true);
        const response = await fetchChangeLabelStatus(label.id, authData.token);
        if (response.ok) {
          setEditStatus(false);
          const newArr = labels.map((el) =>
            el.id === label.id ? { ...el, status: "PENDING" } : el
          );
          dispatch(setLabels(newArr));
        } else if (response.status === 401) {
          localStorage.removeItem("authData");
          dispatch(setUnauthorized(true));
        } else if (response?.status === 404) {
          setNotFound(true);
        }
      }
    } catch (error) {
      setError(true);
    }
    setLoading(false);
  };

  const statusStyle =
    label.status === "PENDING" ? style.pendingStatus : style.printingStatus;

  return (
    <>
      <div className={style.main}>
        <div className={style.window}>
          <div className={style.info}>
            <span>{`Label № ${label.id}`}</span>
            <span>{label.rus_name}</span>
            <span>
              STATUS:
              <p className={statusStyle}>{label.status}</p>
            </span>
          </div>
          {isLoading ? (
            <div className={style.disableLoaderBtn}>
              <div className={style.wloader}></div>
            </div>
          ) : (
            <button onClick={onClickChangeStatus}>CHANGE STATUS</button>
          )}
          <div onClick={() => setEditStatus(false)} className={style.close}>
            <span className={style.one}></span>
            <span className={style.two}></span>
          </div>
          {isError && (
            <span className={style.errorMessage}>Server connection error!</span>
          )}
          {notFound && (
            <span className={style.errorMessage}>Label not found!</span>
          )}
        </div>
      </div>
    </>
  );
};

export default EditLabelStatus;
