import { FC, Fragment, useEffect, useState } from "react";
import { useAppSelector } from "../redux/hooks";

import style from "./Clients.module.scss";

import Sort from "../components/Sort";
import MainTitle from "../components/MainTitle";
import InfoMessage from "../components/InfoMessage";
import NewClient from "../components/Clients/NewClient";
import ClientManagementPanel from "../components/Clients/ClientManagementPanel";
import { useDebounce, useMount } from "../utils/hooks";
import { DELAY, paginate } from "../utils/helpers";
import { ClientResponseType } from "../utils/types";
import { setClients } from "../redux/slices/clientsSlice";

const titles = ["№", "Name", "Email", "Phone"];

const ClientItem: FC<{ client: ClientResponseType }> = ({ client }) => {
  return (
    <div className={`${style.dataTitle}  ${style.item}`}>
      <span>{client.id}</span>
      <span>{client.name}</span>
      <span>{client.email}</span>
      <span>{client.phone}</span>
    </div>
  );
};

const Clients: FC<{ mainTitle: string }> = ({ mainTitle }) => {
  const { clients, isLoading, clientsError } = useAppSelector(
    (state) => state.clients
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [isNewClientAddedMsg, setIsNewClientAddedMsg] = useState(false);
  const mounted = useMount(isNewClientAddedMsg, 300);

  const [localClients, setLocalClients] = useState<Array<ClientResponseType>>(
    []
  );
  const [activeIndexTitle, setActiveIndexTitle] = useState("");

  const [isAddClientClicked, setAddClientClicked] = useState(false);

  const [titleID, setTitleID] = useState("");
  const [titleName, setTitleName] = useState("");
  const [titleEmail, setdTitleEmail] = useState("");
  const [titlePhone, setTitlePhone] = useState("");

  const debouncedTitleID = useDebounce(titleID, DELAY);
  const debouncedTitleName = useDebounce(titleName, DELAY);
  const debouncedTitleEmail = useDebounce(titleEmail, DELAY);
  const debouncedTitlePhone = useDebounce(titlePhone, DELAY);

  useEffect(() => {
    setLocalClients(paginate(clients, currentPage));
  }, [currentPage, clients]);

  useEffect(() => {
    const result = clients.filter(
      (client) =>
        (!client.id || client.id.toString().includes(debouncedTitleID)) &&
        (!client.name ||
          client.name
            .toLowerCase()
            .includes(debouncedTitleName.toLowerCase())) &&
        (!client.email ||
          client.email
            .toLowerCase()
            .includes(debouncedTitleEmail.toLowerCase())) &&
        (!client.phone || client.phone.includes(debouncedTitlePhone))
    );
    setLocalClients(paginate(result, currentPage));
  }, [
    debouncedTitleID,
    debouncedTitleName,
    debouncedTitleEmail,
    debouncedTitlePhone,
    clients,
    currentPage,
  ]);

  useEffect(() => {
    document.title = "Label Matrix | Clients";
  }, []);

  const titleInput = (
    title: string,
    placeholder: string,
    index: string,
    setFn: (value: string) => void
  ) => {
    return (
      <div className={style.inputBox}>
        <input
          type="text"
          value={title}
          placeholder={placeholder}
          onChange={(e) => {
            setFn(e.target.value);
            setCurrentPage(1);
          }}
        />
        <Sort
          index={index}
          data={clients}
          setData={setClients}
          activeIndexTitle={activeIndexTitle}
          setActiveIndexTitle={setActiveIndexTitle}
        />
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className={style.loaderBox}>
        <div className={style.loader}></div>
      </div>
    );
  }

  if (clientsError) {
    return <div className={style.loaderBox}>Server connection error!</div>;
  }

  return (
    <>
      <div className={style.main}>
        <MainTitle title={mainTitle} />
        <div className={style.data}>
          <div className={style.dataTitle}>
            {titleInput(titleID, titles[0], "id", setTitleID)}
            {titleInput(titleName, titles[1], "name", setTitleName)}
            {titleInput(titleEmail, titles[2], "email", setdTitleEmail)}
            {titleInput(titlePhone, titles[3], "phone", setTitlePhone)}
          </div>
          {localClients.map((client) => (
            <Fragment key={client.id}>
              <ClientItem client={client} />
            </Fragment>
          ))}
        </div>
        <ClientManagementPanel
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          onButtonHandler={setAddClientClicked}
        />
      </div>
      {isAddClientClicked && (
        <NewClient
          setAddClientClicked={setAddClientClicked}
          setIsNewClientAddedMsg={setIsNewClientAddedMsg}
        />
      )}
      {mounted && (
        <InfoMessage
          isError={false}
          opened={isNewClientAddedMsg}
          text={"New client added successfully"}
          closeMessage={setIsNewClientAddedMsg}
        />
      )}
    </>
  );
};

export default Clients;
