import { FC } from "react";

import style from "./ScanBarcode.module.scss";

import { svgWarning } from "../../utils/svgBoxes";

interface IWarningLabelNotFound {
  scanResult: string;
  warningCount: number;
  setWarning: (val: boolean) => void;
  addUnacLabelToOrderHandler: () => Promise<void>;
}

const WarningLabelNotFound: FC<IWarningLabelNotFound> = (props) => {
  return (
    <div className={style.warning}>
      <div className={style.warningWindow}>
        <div className={style.title}>
          {svgWarning("#0F3C81")}
          UNACCOUNTED LABELS
        </div>
        <div>Make sure the Barcode is correct:</div>
        <div className={style.barInfo}>{props.scanResult}</div>
        <div>
          {`If the Barcode is valid, then click "CONFIRM" to add
          ${props.warningCount > 1 ? props.warningCount + " labels" : " label"} 
          as an unaccounted ${
            props.warningCount > 1 ? "items" : "item"
          }, if incorrect, then cancel`}
        </div>
        <div className={style.btnBox}>
          <button
            onClick={() => props.setWarning(false)}
            className={style.cancel}
          >
            CANCEL
          </button>
          <button
            onClick={props.addUnacLabelToOrderHandler}
            className={style.confirm}
          >
            CONFIRM
          </button>
        </div>
      </div>
    </div>
  );
};

export default WarningLabelNotFound;
