import React, { FC, useEffect, useState } from "react";

import style from "./Sort.module.scss";

import { useAppDispatch } from "../redux/hooks";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";

interface ISort {
  index: string;
  data: any;
  setData: ActionCreatorWithPayload<any, string>;
  activeIndexTitle: string;
  setActiveIndexTitle: (value: string) => void;
}

const Sort: FC<ISort> = ({
  index,
  data,
  setData,
  activeIndexTitle,
  setActiveIndexTitle,
}) => {
  const [isAsc, setAsc] = useState(true);
  const [click, setClick] = useState(true);
  const [firstRender, setFirstRender] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (firstRender) {
      let result = data.map((el: any) => ({ ...el }));
      if (
        index === "id" ||
        index === "client_id" ||
        index === "worker_id" ||
        index === "labels_count"
      ) {
        result.sort((a: any, b: any) => a[index] - b[index]);
      } else if (index === "created_at") {
        result.sort((a: any, b: any) => {
          const dateA = new Date(a.created_at).getTime();
          const dateB = new Date(b.created_at).getTime();
          return dateA - dateB;
        });
      } else {
        result.sort((a: any, b: any) => {
          if (!a[index] || !b[index]) return 0;
          return a[index].localeCompare(b[index]);
        });
      }
      if (!isAsc) result.reverse();
      dispatch(setData(result));
    }
    setFirstRender(true);
  }, [click, dispatch, index, setData]);

  const ascStyle =
    isAsc && activeIndexTitle === index ? `${style.activeSort}` : "";
  const descStyle =
    !isAsc && activeIndexTitle === index ? `${style.activeSort}` : "";

  return (
    <div className={style.ascDesc}>
      <div
        className={style.asc}
        onClick={() => {
          setAsc(true);
          setClick(!click);
          setActiveIndexTitle(index);
        }}
      >
        <div className={`${style.line} ${style.one} ${ascStyle}`}></div>
        <div className={`${style.line} ${style.two} ${ascStyle}`}></div>
      </div>
      <div
        className={style.desc}
        onClick={() => {
          setAsc(false);
          setClick(!click);
          setActiveIndexTitle(index);
        }}
      >
        <div className={`${style.line} ${style.one} ${descStyle}`}></div>
        <div className={`${style.line} ${style.two} ${descStyle}`}></div>
      </div>
    </div>
  );
};

export default Sort;
